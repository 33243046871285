import React from "react";
import { HexColor } from "@pepdirect/shared/types";
import { colors } from "../../styles/variables";

export const PlusIconSvg = ({ color }: { color?: HexColor }) => (
  <svg
    width="10"
    height="10"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 20C9.44772 20 9 19.5523 9 19L9 1C9 0.447716 9.44772 -2.8483e-09 10 -6.36184e-09C10.5523 -9.87539e-09 11 0.447716 11 1L11 19C11 19.5523 10.5523 20 10 20Z"
      fill={color || colors.darkGray}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0 10C0 9.44772 0.447715 9 1 9H19C19.5523 9 20 9.44772 20 10C20 10.5523 19.5523 11 19 11H1C0.447715 11 0 10.5523 0 10Z"
      fill={color || colors.darkGray}
    />
  </svg>
);
