import { useState } from "react";
import { GqlModule, ModulesContextInterface } from "types/module";

export function useModulesProvider(modulesParam?: GqlModule[]): {
  modulesContextValue: ModulesContextInterface;
  modules: GqlModule[];
} {
  const [modules, setModules] = useState<GqlModule[]>(modulesParam || []);

  const modulesContextValue: ModulesContextInterface = {
    modules,
    setModules: (modules: GqlModule[]) => {
      setModules(modules);
    },
  };

  return { modulesContextValue, modules };
}
