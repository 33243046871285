import { useState } from "react";
import { UserContextInterface } from "types/user";
import {
  CurrentUserQuery,
  useLogoutUserMutation,
} from "services/graphql/generated";
import { ApolloClient, NormalizedCacheObject } from "@apollo/client";

export function useUserProvider(
  user: CurrentUserQuery["currentUser"] | null,
  client: ApolloClient<NormalizedCacheObject>
): {
  userContextValue: UserContextInterface;
} {
  const [currentUserId, setCurrentUserId] = useState<string | null>(
    user?.id || null
  );

  const [logoutUser] = useLogoutUserMutation({ client });

  const handleLogout = async () => {
    try {
      await logoutUser();
    } catch (e) {
      // logged in errorLink
    } finally {
      setCurrentUserId(null);
      // for some unknown reason, router.push("/") destroys the
      // react-select styles, so using the below as a workaround
      // https://pepsico-ecomm.atlassian.net/browse/B2B-1949
      window.location.pathname = "/";
    }
  };

  const userContextValue: UserContextInterface = {
    currentUserId,
    logout: handleLogout,
    setUser: (userId: string) => {
      setCurrentUserId(userId);
    },
  };

  return { userContextValue };
}
