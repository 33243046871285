import { ThemeInterface, themeNames } from "../theme";
import { colors } from "../../styles/variables";
import { fontWeights, HexColor } from "@pepdirect/shared/types";

type SodaStreamProColorNames =
  | "sodaStreamProBlue"
  | "darkBlue"
  | "darkGray"
  | "lightGray"
  | "mediumGray"
  | "textGray";

export const SODASTREAMPRO_COLORS: Record<SodaStreamProColorNames, HexColor> = {
  sodaStreamProBlue: "#009cdd",
  darkBlue: "#0047bb",
  darkGray: "#53565a",
  lightGray: "#BBBCBC",
  textGray: "#333",
  mediumGray: "#9A9A9A",
};

const SHARED_HEADING_STYLES = {
  fontWeight: fontWeights["700"],
  color: SODASTREAMPRO_COLORS.textGray,
  lineHeight: "1.3",
};

export const SodaStreamProTheme: ThemeInterface = {
  name: themeNames.SodaStreamPro,
  color: {
    primary: SODASTREAMPRO_COLORS.sodaStreamProBlue,
    secondary: SODASTREAMPRO_COLORS.darkBlue,
    tertiary: SODASTREAMPRO_COLORS.darkGray,
    accent: SODASTREAMPRO_COLORS.lightGray,
    disabled: colors.lightestGray,
    action: colors.green,
    error: colors.red,
    text: SODASTREAMPRO_COLORS.textGray,
  },
  button: {
    primary: {
      color: SODASTREAMPRO_COLORS.sodaStreamProBlue,
    },
    secondary: {
      color: SODASTREAMPRO_COLORS.darkBlue,
    },
    tertiary: {
      color: SODASTREAMPRO_COLORS.sodaStreamProBlue,
      hollow: true,
    },
    cancel: {
      color: colors.mediumGray,
      hollow: true,
    },
    error: {
      color: colors.red,
      hollow: true,
    },
    text: {
      color: SODASTREAMPRO_COLORS.darkBlue,
    },
    fontWeight: fontWeights["700"],
    borderRadius: "20px",
  },
  typography: {
    body: {
      lineHeight: "1.5",
      color: SODASTREAMPRO_COLORS.textGray,
    },
    heading: {
      xxl: {
        fontSize: {
          desktop: "46px",
          mobile: "32px",
        },
        letterSpacing: "-1px",
        ...SHARED_HEADING_STYLES,
      },
      xl: {
        fontSize: {
          desktop: "32px",
          mobile: "24px",
        },
        letterSpacing: "-1px",
        ...SHARED_HEADING_STYLES,
      },
      l: {
        fontSize: {
          desktop: "24px",
          mobile: "20px",
        },
        letterSpacing: "-1px",
        ...SHARED_HEADING_STYLES,
      },
      m: {
        fontSize: {
          desktop: "18px",
          mobile: "18px",
        },
        ...SHARED_HEADING_STYLES,
      },
      s: {
        fontSize: {
          desktop: "16px",
          mobile: "16px",
        },
        ...SHARED_HEADING_STYLES,
      },
      xs: {
        fontSize: {
          desktop: "14px",
          mobile: "14px",
        },
        ...SHARED_HEADING_STYLES,
      },
      xxs: {
        fontSize: {
          desktop: "14px",
          mobile: "14px",
        },
        fontWeight: fontWeights["400"],
      },
    },
  },
  header: {
    portal: {
      backgroundColor: SODASTREAMPRO_COLORS.sodaStreamProBlue,
      navLinkColor: colors.white,
    },
    shop: {
      backgroundColor: colors.white,
      navLinkColor: colors.darkGray,
    },
    simple: {
      checkout: {
        backgroundColor: SODASTREAMPRO_COLORS.sodaStreamProBlue,
      },
      portal: {
        backgroundColor: SODASTREAMPRO_COLORS.sodaStreamProBlue,
      },
    },
  },
  footer: {
    backgroundColor: SODASTREAMPRO_COLORS.mediumGray,
    copyright: {
      backgroundColor: SODASTREAMPRO_COLORS.darkGray,
      textColor: "#fff",
    },
  },
  priceAccentColor: SODASTREAMPRO_COLORS.darkBlue,
  banner: {
    backgroundColor: SODASTREAMPRO_COLORS.sodaStreamProBlue,
  },
};
