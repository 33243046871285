import React, { useState, useContext, useEffect } from "react";

import { ModalContext } from "context/modal";
import { UserContext } from "context/user";
import { CartContext } from "context/cart";
import { AppContext } from "context/app";
import { useIsMobile } from "@pepdirect/helpers/useIsMobile";
import { useRouterWithRedirect } from "hooks/useRouterWithRedirect";
import { useGenericOnClickRouter } from "hooks/useGenericOnClickRouter";
import { NavHeader as PepNavHeader } from "@pepdirect/ui/NavHeader";
import { HeaderLocationProps } from "@pepdirect/ui/header/navheader/types";
import { logNavigationClickEvent } from "@pepdirect/helpers/analyticsLogger";
import { endpoints, links, headerPromotion } from "config";
import { isFeatureFlagEnabled } from "@pepdirect/helpers";
import { getSdk } from "services/datocms/generated";
import { createDatoClient } from "services/datocms";

export function NavHeader(): JSX.Element {
  const { open } = useContext(ModalContext);
  const { featureFlags } = useContext(AppContext);
  const { currentUserId, logout } = useContext(UserContext);
  const { getSize } = useContext(CartContext);
  const size = getSize();
  const { pushPreserveRedirect } = useRouterWithRedirect();
  const { route } = useGenericOnClickRouter();

  const isMobile = useIsMobile();

  const [headerKey, setHeaderKey] = useState("h");

  interface NavItem {
    navHeaderDisplay: string;
    navHeaderLink: string;
  }

  const [linksForLoggedInUser, setLinksForLoggedInUser] = useState<NavItem[]>(
    []
  );

  useEffect(() => {
    getSdk(createDatoClient())
      .mainHeader()
      .then((res) => {
        const navItems: NavItem[] = [];
        res.mainHeader?.navigation?.map((item) =>
          navItems.push({
            navHeaderDisplay: item.title || "",
            navHeaderLink: item.url || "",
          })
        );
        setLinksForLoggedInUser(navItems);
      });
  }, []);

  const searchData = {
    showSearchBar: false,
    placeholderText: ``,
    onSearchHandler: () => {},
  };

  const goToSignIn = () => {
    if (isMobile) {
      // HACK to reset header, should be removed later
      setHeaderKey(`${headerKey}-1`);
      pushPreserveRedirect("/sign-in");
    } else {
      open("LOGIN_MODAL");
    }
  };

  const logoutAndResetHeader = () => {
    if (isMobile) {
      // HACK to reset header, should be removed later
      setHeaderKey(`${headerKey}-1`);
    }
    logout();
  };

  const userData = {
    isLoggedIn: !!currentUserId,
    accountUrl: `${endpoints.portalFrontend}/dashboard/account/`,
    onLogInClicked: goToSignIn,
    onLogOutClicked: logoutAndResetHeader,
  };

  const location: HeaderLocationProps = {
    showLocation: !!(
      currentUserId && isFeatureFlagEnabled(featureFlags, "geo_location")
    ),
    onClickHandler: () => {},
    zipCode: null,
  };

  const headerData = {
    promotion: headerPromotion,
    search: searchData,
    logo: {
      logoImgLink: links.logoDark,
      logoUrl: `/category/standalone`,
      customWidthOnMobile: 120,
      leftAlignOnMobile: true,
    },
    navBar: {
      onClickHandler: (e: React.MouseEvent<HTMLAnchorElement>) => {
        const { innerText } = e.target as HTMLElement;
        logNavigationClickEvent(currentUserId, innerText);
        route(e);
      },
      navItems: currentUserId ? linksForLoggedInUser : [],
      user: userData,
      searchBar: searchData,
      location,
    },
    shoppingCart: {
      numOfItems: size,
      onClickHandler: (e: React.MouseEvent<HTMLAnchorElement>) =>
        route(e, "/cart"),
    },
    user: userData,
    location,
    customButtonText: "Get Started",
    customButtonLink: "/get-started",
  };

  return (
    <div className={currentUserId ? "header-logged-in" : "header-logged-out"}>
      <PepNavHeader key={headerKey} {...headerData} />
    </div>
  );
}
