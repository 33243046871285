import React, { useContext, useEffect, useState } from "react";
import { useRouter } from "next/router";
import { ModalContext } from "context/modal";
import { UserContext } from "context/user";
import { LoginForm as PepLoginForm } from "@pepdirect/ui/login";
import { logEvent } from "@pepdirect/helpers/analyticsLogger";
import { LoginDTO } from "types/user";
import { links } from "config";
import { EVENT_NAMES } from "@pepdirect/shared/types";
import { parseLoginGraphQLErrors } from "@pepdirect/v3/helpers/errors";
import { UpdatePassword } from "@pepdirect/v3/ui/UpdatePassword";
import { useLoginMutation } from "services/graphql/generated";
import { ApolloError } from "@apollo/client";
import { GraphQLError } from "graphql";

export function LoginForm({
  inModal = false,
}: {
  inModal?: boolean;
}): JSX.Element {
  const [loginMutation, { loading }] = useLoginMutation();
  const [mappedErrorMessage, setMappedErrorMessage] = useState("");
  const [showUpdatePasswordScreen, setShowUpdatePasswordScreen] =
    useState(false);

  const { open, close } = useContext(ModalContext);
  const { setUser } = useContext(UserContext);
  const router = useRouter();

  useEffect(() => {
    router.prefetch("/category/standalone");
  }, [router]);

  const onSubmit = async (formValue: LoginDTO) => {
    setMappedErrorMessage("");
    try {
      const { data } = await loginMutation({
        variables: { email: formValue.login, password: formValue.password },
      });
      setUser(data?.login?.id);
      logEvent(data?.login?.id, EVENT_NAMES.userLoginSuccess);
      router.push("/category/standalone");
      close();
    } catch (e) {
      if (e instanceof ApolloError && e.graphQLErrors.length) {
        const { errorMsg, passwordResetRequired } = parseLoginGraphQLErrors(
          e.graphQLErrors as GraphQLError[]
        );

        if (passwordResetRequired) {
          setShowUpdatePasswordScreen(true);
        } else {
          setMappedErrorMessage(errorMsg);
        }
      }
      return;
    }
  };

  const goToForgotPassword = () => {
    if (inModal) {
      open("FORGOT_PASSWORD_MODAL");
    } else {
      close();
      router.push("/forgot-password");
    }
  };

  if (showUpdatePasswordScreen) {
    return <UpdatePassword />;
  }

  return (
    <PepLoginForm
      logoSrc={inModal ? links.logoDark : ""}
      onSubmit={onSubmit}
      goToForgotPassword={goToForgotPassword}
      isLoading={loading}
      error={mappedErrorMessage || ""}
    />
  );
}
