import { useState } from "react";
import { BannerContextInterface, BannerDetails } from "@pepdirect/ui/banner";

export function useBannerProvider(): {
  bannerContextValue: BannerContextInterface;
} {
  const [bannerContent, setBannerContent] = useState<BannerDetails | null>(
    null
  );
  const [dismissBanner, setDismissBanner] = useState(false);

  const bannerContextValue = {
    setBannerDetails: (details: BannerDetails | null) =>
      setBannerContent(details),
    bannerDetails: bannerContent,
    setBannerAutoDismiss: (dismiss: boolean) => setDismissBanner(dismiss),
    bannerAutoDismiss: dismissBanner,
  };

  return { bannerContextValue };
}
