import React from "react";
import st from "./takeACloserLook.module.scss";
import { endpoints } from "config";

const ASSET_ROOT_PATH = `${endpoints.cdn}/sodastreampro/takeACloserLook/flavors/`;
const flavors = [
  {
    img: "flavor10.png",
    name: "Strawberry",
    line2: "0 calories / 12 fl oz",
  },
  {
    img: "flavor4.png",
    name: "Orange Grapefruit",
    line2: "0 calories / 12 fl oz",
  },
  {
    img: "flavor5.png",
    name: "Lemon Mint",
    line2: "0 calories / 12 fl oz",
  },
  {
    img: "flavor6.png",
    name: "Peach",
    line2: "0 calories / 12 fl oz",
  },
  {
    img: "flavor12.png",
    name: "Lime",
    line2: "0 calories / 12 fl oz",
  },
  {
    img: "flavor8.png",
    name: "Raspberry Lime",
    line2: "0 calories / 12 fl oz",
  },
  {
    img: "Cucumber_CS.png",
    name: "Coming Soon",
    line2: "",
  },
  {
    img: "Lemon_Ginger_CS.png",
    name: "Coming Soon",
    line2: "",
  },
  {
    img: "Peach_Ginger_CS.png",
    name: "Coming Soon",
    line2: "",
  },
];

export function TakeACloserLookModal(): JSX.Element {
  return (
    <div className={st.wrapper}>
      <img
        className={st.machineDiagram}
        src={`${endpoints.cdn}/sodastreampro/takeACloserLook/machine.png`}
        alt="Machine Diagram"
      />

      <div className={st.centerColumn}>
        <div className={st.infoBlock}>
          <div>
            <img
              className={st.waterDroplet}
              src={`${endpoints.cdn}/sodastreampro/takeACloserLook/droplet.png`}
              width={24}
              height={28}
              alt="Water Droplet"
            />
          </div>
          <div>
            <h3>FLEXIBLE PAYMENT OPTION</h3>
            <p>
              Pay per pour via optional credit card reader; ability to implement
              subscriptions
            </p>
          </div>
        </div>
        <div className={st.infoBlock}>
          <div>
            <img
              className={st.waterDroplet}
              src={`${endpoints.cdn}/sodastreampro/takeACloserLook/droplet.png`}
              width={24}
              height={28}
              alt="Water Droplet"
            />
          </div>
          <div>
            <h3>DRAIN FREE OPTION</h3>
            <p>
              Allow for streamline installation and optimal placement. Requires
              periodic emptying of removable drip tray
            </p>
          </div>
        </div>
        <div className={st.infoBlock}>
          <div>
            <img
              className={st.waterDroplet}
              src={`${endpoints.cdn}/sodastreampro/takeACloserLook/droplet.png`}
              width={24}
              height={28}
              alt="Water Droplet"
            />
          </div>
          <div>
            <h3>ADA FEATURES</h3>
            <p>Design includes features aligned with ADA/508 US Standards</p>
          </div>
        </div>
        <div className={st.adaIconsWrapper}>
          <img
            className={st.adaIcons}
            src={`${endpoints.cdn}/sodastreampro/takeACloserLook/adaIcons.png`}
            width={190}
            height={54}
            alt="ADA Icons"
          />
        </div>
      </div>

      <div className={st.flavorsWrapper}>
        <h4 className={st.heading}>Available Flavors</h4>
        <div className={st.flavorsGrid}>
          {flavors.map(({ img, name, line2 }, index) => (
            <div className={st.flavor} key={`${index}-${name}`}>
              <img src={`${ASSET_ROOT_PATH}${img}`} alt={name} />
              <div className={st.name}>{name}</div>
              <div className={st.line2}>{line2}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
