type FeatureFlag = {
  name: string;
  enabled: boolean;
};

export const isFeatureFlagEnabled = (
  featureFlags: FeatureFlag[],
  featureName: string
): boolean => {
  const isEnabled = (featureFlags || []).some(
    (featureFlag) => featureFlag.name === featureName && featureFlag.enabled
  );

  return !!isEnabled;
};
