import getConfig from "next/config";

const { publicRuntimeConfig } = getConfig();

export const environment = publicRuntimeConfig.environment as string;
export const sentryEnvironment =
  publicRuntimeConfig.sentryEnvironment as string;
export const isProductionReady = ["staging", "production", "uat"].includes(
  environment
);
export const isLocalEnv = !isProductionReady && !process.env.CI;

export const decovoShopAppId = "808c8c05-42d8-4b2e-bd08-357523ac9788";
export const sentryDSN = publicRuntimeConfig.sentryDSN as string;

export const imgWidths = [345, 600, 690];

export const shipping = {
  us: {
    free: 0,
    below: "0",
    threshold: 1000000000000000000000000,
  },
};

export const cart = {
  discounts: {
    hideDiscountsMaxThreshold: 2,
    showSavingsMaxThreshold: 10,
  },
  maxCartQty: 15,
};

export const cookies = {
  localStorageUserKey: "caps_user",
  cartCookieName: "decovo-shoppingcart",
  secureCookie: isProductionReady,
};

export const endpoints = {
  cdn: publicRuntimeConfig.cdn as string,
  productsImgCdn: publicRuntimeConfig.productsImgCdn as string,
  web: publicRuntimeConfig.web as string,
  api: publicRuntimeConfig.api as string,
  cart: publicRuntimeConfig.cart as string,
  checkout: publicRuntimeConfig.checkout as string,
  portalService: publicRuntimeConfig.portalService as string,
  portalFrontend: publicRuntimeConfig.portalFrontend as string,
  corporateFrontend: publicRuntimeConfig.corporateFrontend as string,
  internalApi: publicRuntimeConfig.internalApi as string,
  datoCmsApiToken: publicRuntimeConfig.datoCmsApiToken as string,
  datoCmsEndpoint: publicRuntimeConfig.datoCmsEndpoint as string,
};

export const headerPromotion = {
  shouldShow: false,
  isAlwaysVisible: false,
  promotionText: "Free Shipping Over $20",
  transparentYOffsetThreshold: 2,
};

export const links = {
  logoDark:
    "https://assets.decovostatic.com/static-images/sodastream/SSTP-Grey.svg",
  logoLight:
    "https://assets.decovostatic.com/static-images/sodastream/SSTP-White.svg",
  favicon:
    "https://assets.decovostatic.com/static-images/sodastream/droplet-favicon.png",
  support: "SPA-PEPSodaStreamProfessionalCustomerSupport@pepsico.com",
  sales: "Customerservicesodastreamprofessional@pepsico.com",
  privacy: "https://policy.pepsi.com/privacy.htm",
};

export interface BenefitsCard {
  img: string;
  title: string;
  text: string;
}

export const healthyHydrationBenefits: BenefitsCard[] = [
  {
    img: `${endpoints.cdn}/sodastreampro/healthyHydrationBenefits/Column1.png`,
    title: "Personalized Drinks",
    text: "Customize flavor, temperature and sparkling levels and save favorites",
  },
  {
    img: `${endpoints.cdn}/sodastreampro/healthyHydrationBenefits/Column2.png`,
    title: "Exceptional Taste",
    text: "Pour refreshing, nano-filtered water every time",
  },
  {
    img: `${endpoints.cdn}/sodastreampro/healthyHydrationBenefits/Column3.png`,
    title: "Hydration Tracking",
    text: "Achieve daily wellness goals with automatic progress tally",
  },
];

export const sustainableChoiceBenefits: BenefitsCard[] = [
  {
    img: `${endpoints.cdn}/sodastreampro/sustainableChoiceBenefits/Column1.png`,
    title: "Positive Action",
    text: "Celebrate eco-conscious values and champion transformational change",
  },
  {
    img: `${endpoints.cdn}/sodastreampro/sustainableChoiceBenefits/Column2.png`,
    title: "Connected Smart Bottle",
    text: "Pair with the app for a personal experience",
  },
  {
    img: `${endpoints.cdn}/sodastreampro/sustainableChoiceBenefits/Column3.png`,
    title: "Measurable Impact",
    text: "Reduce plastic waste and quantify individual impact",
  },
];

export const intelligentConvenienceBenefits: BenefitsCard[] = [
  {
    img: `${endpoints.cdn}/sodastreampro/intelligentConvenienceBenefits/Column1.png`,
    title: "Insightful Data",
    text: "Monitor usage, impact and inventory through customer portal",
  },
  {
    img: `${endpoints.cdn}/sodastreampro/intelligentConvenienceBenefits/Column2.png`,
    title: "Seamless Operations",
    text: "Enables proactive re-order, and remote equipment monitoring",
  },
  {
    img: `${endpoints.cdn}/sodastreampro/intelligentConvenienceBenefits/Column3.png`,
    title: "Safe and Accessible",
    text: "Offers optimized accessibility and contactless pour functionality",
  },
];
