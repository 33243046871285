import React, { useState } from "react";
import st from "./videoModal.module.scss";
import { endpoints } from "../../../config";
import classNames from "classnames";

export function VideoModal(): JSX.Element {
  const [loaded, setLoaded] = useState(false);
  return (
    <div
      className={classNames(st.wrapper, {
        [st.loaded]: loaded,
      })}
    >
      <video
        id="videoLink"
        controls
        autoPlay={true}
        className={classNames(st.videoLink, {
          [st.loaded]: loaded,
        })}
        onLoadedData={() => {
          setLoaded(true);
        }}
      >
        <source src={`${endpoints.cdn}/sodastreampro/SSP.mov`} />
      </video>
    </div>
  );
}
