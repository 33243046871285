import React, { useContext, useEffect } from "react";
import Modal from "react-modal";
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

import { ModalContext } from "context/modal";
import st from "./modal.module.scss";

/* Modals */
import { LoginModal } from "./LoginModal";
import { ForgotPasswordModal } from "./ForgotPasswordModal";
import { TakeACloserLookModal } from "./TakeACloserLookModal";
import { VideoModal } from "./VideoModal";

export function RootModal(): JSX.Element {
  const { isOpen, close, type } = useContext(ModalContext);
  useEffect(() => {
    if (typeof window !== "undefined") {
      Modal.setAppElement("#__next");
    }
    // Cleanup just in case
    return clearAllBodyScrollLocks;
  }, []);

  return (
    <Modal
      onAfterClose={clearAllBodyScrollLocks}
      // Still need to check ref because the ref returns null while modal is closing.
      overlayRef={(ref) =>
        ref && disableBodyScroll(ref, { reserveScrollBarGap: true })
      }
      isOpen={isOpen}
      onRequestClose={close}
      className={st.modal}
      overlayClassName={st.overlay}
      closeTimeoutMS={250}
    >
      <button className={st.close} onClick={close} aria-label="Close">
        <span aria-hidden="true">×</span>
      </button>
      {/* TODO: lazy-load? */}
      {type === "LOGIN_MODAL" && <LoginModal />}
      {type === "FORGOT_PASSWORD_MODAL" && <ForgotPasswordModal />}
      {type === "TAKE_A_CLOSER_LOOK_MODAL" && <TakeACloserLookModal />}
      {type === "SSP_VIDEO_MODAL" && <VideoModal />}
    </Modal>
  );
}
